import React, { useState, useEffect } from 'react'
import Modules from './Modules'
import SingleUseModules from './SingleUseModules'
import { useNavigate } from 'react-router'
import Categories from './Categories'
import CategoriesOffers from './CategoriesOffers'
import CategoriesFAQ from './CategoriesFAQ'
import _ from 'lodash'
import Addresses from './Addresses'
import SelectTrack from './SelectTrack'
import SelectArticle from './SelectArticle'
import SelectOffer from './SelectOffer'
import SelectEvent from './SelectEvent'
import AddTerm from './AddTerm'
import { errorMap, errorInputValidator } from './BaseComponent'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import MultiselectComponent from './Multiselect'
import SingleImageUpload from './SingleImageUpload'
import { useSearchParams } from 'react-router-dom'
import Modal from './Modal'

registerLocale('pl', pl)

export default function AddPage({ API, user, data }) {
  const [searchParams] = useSearchParams()
  const [importedData, setImportedData] = useState("")
  const [showImport, setShowImport] = useState(false)
  const [showImportError, setShowImportError] = useState(false)
  let paramsObj = {}

  for (let i = 0; i < data.fields.length; i++) {
    const element = data.fields[i];
    const d = new Date();
    d.setSeconds(0)
    const dateTimeLocalValue = d.toISOString()
    _.set(paramsObj, element.name, element.type === "colorpicker" ? "#000000" : element.type === "checkbox" ? (element.checked ? true : false) : element.type === "number" ? element.defaultValue || 0 : element.type === "text" || element.type === "slug" || element.type === "textarea"  ? "" : element.type === "modules" ? [] : element.type === "date" ? dateTimeLocalValue : element.type === "datecontest" ? dateTimeLocalValue : element.type === "terms" ? {file: {uuid: "", restricted: true}} : null)
  }

  if(data.singleUseModules && data.singleUseModules.length > 0) {
    for (let i = 0; i < data.singleUseModules.length; i++) {
      const element = data.singleUseModules[i];
      let tempParams= _.cloneDeep(paramsObj)
      let tempModules = _.get(tempParams, "content.modules")
      tempModules.push({ type: element, singleUse: true, metadata: {} })
      _.set(tempParams, "content.modules", tempModules)
      paramsObj = tempParams
    }
  }

  const history = useNavigate()
  const [preloader, setPreloader] = useState(false)
  const [params, setParams] = useState(paramsObj)
  const [errors, setErrors] = useState({})

  const getSlug = (name) => {
    let tempParams = {...params}
    let value = tempParams.content?.title ? tempParams.content.title : tempParams.content?.name ? tempParams.content.name : tempParams.title ? tempParams.title : tempParams.name 
    if(value) {
      value = value.toLowerCase()
      var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;ęĘóÓąĄśŚłŁżŻźŹćĆńŃ"
      var to   = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------eEoOaAsSlLzZzZcCnN"

      for (var i = 0, l = from.length; i < l; i++) {
        value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      value = value.replace(/[^a-z0-9 -]/g, '')
              .replace(/\s+/g, '-')
              .replace(/-+/g, '-')
      _.set(tempParams, name, value)
      setParams(tempParams)
    }
  }

  const validateInput = (i) => {
    const input = i
    const name = input.name
    const value = input.value

    if(![name] || value === "" || value === false || value === "false") {
        let err = errors
        err[name] = "Pole wymagane"
        setErrors({...err})
        setTimeout(() => { document.querySelector("input[name='" + name + "']")?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
          setTimeout(() => {
            document.querySelector("input[name='" + name + "']")?.focus()
          }, 300)
          setTimeout(() => {
            document.querySelector("input[name='" + name + "']")?.classList.add("error-single")
            setTimeout(() => {
              document.querySelector("input[name='" + name + "']")?.classList.remove("error-single")
            }, 3000)
          }, 300)
        }, 300)
        return false
      } else if(name !== "buildingNumber" && value.length < 3) {
        let err = errors
        err[name] = "Wpisz poprawną wartość"
        setErrors({...err})
        setTimeout(() => { document.querySelector("input[name='" + name + "']")?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
          setTimeout(() => {
            document.querySelector("input[name='" + name + "']")?.focus()
          }, 300)
        }, 300)
        return false
      } else {
        let err = errors
        err[name] = false
        setErrors({...err})
        return true
    }
  }

  const validateForm = (callback) => {
    let wrongCounter = 0
    const inputs = document.querySelectorAll('input:required')

    for (let i = inputs.length-1; i >= 0; i--) {
        const input = inputs[i];
        if(!validateInput(input)) {
            wrongCounter++
        }
    }

    if(wrongCounter === 0) {
      callback()
    }
  }

  useEffect(() => {
    if(showImport) {
      navigator.clipboard.readText()
      .then(text => {
        setImportedData(text)
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
    }
  }, [showImport])

  const importData = () => {
    var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    if(importedData) {
      if(base64regex.test(importedData)) {
          const d = JSON.parse(decodeURIComponent(escape(window.atob(importedData))))
          if(d.url !== data.endpointEdit) {
            setShowImportError("Wrong data table")
          } else {
            setParams(d[data.table])
            setShowImport(false)
          }
        } else {
          setShowImportError("Wrong data table")
        }
      } else {
        setShowImportError("Empty data.")
      }
    }

  const save = () => {
    // setPreloader(true)
    let payload = {
      [data.table]: params
    }

    if(params.content?.modules && _.filter(params.content?.modules, { type: "address" }).length > 0) {
      if(params.content.modules[3].metadata.latitude === "") params.content.modules[3].metadata.latitude = null
      if(params.content.modules[3].metadata.longitude === "") params.content.modules[3].metadata.longitude = null
    }

    if(data.table === "catalogue" && !params.level) {
      payload[data.table].level = 1
    }

    if(params.profileSettings === "profiled") {
      params.profileFlags = ["has_profile"]
      params.forbiddenProfileFlags = []
      params.profiled = true
      params.notProfiled = false
    } else if(params.profileSettings === "notProfiled") {
      params.profileFlags = []
      params.forbiddenProfileFlags = []
      params.profiled = false
      params.notProfiled = true
    } else if(params.profileSettings === "hybrid") {
      params.profileFlags = ["has_profile"]
      params.forbiddenProfileFlags = []
      params.profiled = false
      params.notProfiled = false
    }
    
    let url = API+data.endpointAdd
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(payload)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        errorInputValidator(result.data?.error?.ident)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error, result.data?.details)}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie dodano."}}), true);
        history(data.route)
      }
      setPreloader(false)
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Something went wrong."}}), true);
      setPreloader(false)
    })
  }

  useEffect(() => {
    if (user.role !== "ADMIN" && user.role !== "Moderator") {
      let elements = document.querySelectorAll("input, textarea, select")
      elements.forEach((element) => {
        element.disabled = true
      })
    }

    if(searchParams.get("category")) {
      let tempParams = {...params}
      _.set(tempParams, "category", searchParams.get("category"))
      setParams(tempParams)
    }

    if(searchParams.get("group")) {
      let tempParams = {...params}
      _.set(tempParams, "group", searchParams.get("group"))
      setParams(tempParams)
    }

    // eslint-disable-next-line
  }, [])

  const fields = data.fields.map((item, i) => {
    if (item.type === "text") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <input type="text" name={ item.name } value={ _.get(params, item.name) } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "select") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <select  name={ item.name } value={ _.get(params, item.name) || "" } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false } disabled={ item.disabled ? true : false }>
            { item.values.map((option, j) => {
              return (
                <option key={j} value={option.val}>{option.name}</option>
              )
            }) }
          </select>
        </div>
      )
    } else if (item.type === "select_add") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <select  name={ item.name } value={ _.get(params, item.name) || "" } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false } disabled={ item.disabled ? true : false }>
            { item.values.map((option, j) => {
              return (
                <option key={j} value={option.val}>{option.name}</option>
              )
            }) }
          </select>
        </div>
      )
    } if (item.type === "colorpicker") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <input type="color" name={ item.name } value={ _.get(params, item.name) } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "slug") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="slug-input">
          {/* { item.desc ? <p className="input-desc" dangerouslySetInnerHTML={{ __html: item.desc }}></p> : null } */}
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          <div className="flex-input">
            <input type="text" name={ item.name } value={ _.get(params, item.name) } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false }/>
            <button className="btn" onClick={ (e) => { e.preventDefault(); getSlug(item.name) } }>Generate</button>
          </div>
        </div>
      )
    } else if (item.type === "datecontest") {
      const dateTimeLocalValue = !_.get(params, item.name) ? new Date() : new Date(_.get(params, item.name));
      // console.log(dateTimeLocalValue)
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <DatePicker
            selected={dateTimeLocalValue}
            onChange={(dt) => {
              let tempParams = {...params};
              const dUTC = dt.toISOString()
              _.set(tempParams, item.name, dUTC);
              setParams(tempParams) 
            }}
            locale="pl"
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            timeCaption="Hour"
          />
        </div>
      )
    } else if (item.type === "date") {
      const dateTimeLocalValue = _.get(params, item.name) === null ? new Date(_.get(params, "expires")) : new Date();
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <DatePicker
            selected={dateTimeLocalValue}
            onChange={(dt) => {
              let tempParams = {...params};
              const dUTC = new Date().toISOString()
              _.set(tempParams, item.name, dUTC);
              setParams(tempParams) 
            }}
            locale="pl"
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            timeCaption="Hour"
          />
        </div>
      )
    } else if (item.type === "modules") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <SingleUseModules availableModules={ data.singleUseModules } API={ API } items={ _.get(params, item.name) } changeModuleItems={ (items) => {
            let tempParams = _.cloneDeep(params)
            _.set(tempParams, item.name, items)
            setParams(tempParams)
          } }/>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <Modules availableModules={ data.availableModules } API={ API } items={ _.get(params, item.name) } changeModuleItems={ (items) => {
            let tempParams = _.cloneDeep(params)
            _.set(tempParams, item.name, items)
            setParams(tempParams)
          } } addModule={ () => {
            let tempParams= _.cloneDeep(params)
            let tempModules = _.get(tempParams, item.name)
            tempModules.push({ type: "", custom: "", position: 0})
            _.set(tempParams, item.name, tempModules)
            setParams(tempParams)
            } } onFilesChange={ (files) => {  } }/>
        </div>
      )
    } else if (item.type === "category") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <Categories API={ API } items={ _.get(params, item.name) } selectCategory={ (cat) => { 
            let tempParams = {...params}
            _.set(tempParams, item.name, cat)
            setParams(tempParams)
          } } selectedCat={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "category_offer") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <CategoriesOffers API={ API } items={ _.get(params, item.name) } selectCategory={ (cat) => { 
            let tempParams = {...params}
            _.set(tempParams, item.name, cat)
            setParams(tempParams)
          } } selectedCat={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "category_faq") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <CategoriesFAQ API={ API } items={ _.get(params, item.name) } selectCategory={ (cat) => { 
            let tempParams = {...params}
            _.set(tempParams, item.name, cat)
            setParams(tempParams)
          } } selectedCat={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "select_track") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <SelectTrack API={ API } items={ _.get(params, item.name) } selectTrack={ (track) => { 
            let tempParams = {...params}
            if(track === "") {
              track = null
            } else {
              track = {uuid: track}
            }
            _.set(tempParams, item.name, track)
            setParams(tempParams)
          } } selectedTr={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "select_article") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <SelectArticle API={ API } selectItem={ (el) => { 
            let tempParams = {...params}
            el = {uuid: el}
            _.set(tempParams, item.name, el)
            setParams(tempParams)
          } } selected={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "select_offer") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <SelectOffer API={ API } selectItem={ (el) => { 
            let tempParams = {...params}
            el = {uuid: el}
            _.set(tempParams, item.name, el)
            setParams(tempParams)
          } } selected={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "select_event") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <SelectEvent API={ API } selectItem={ (el) => { 
            let tempParams = {...params}
            el = {uuid: el}
            _.set(tempParams, item.name, el)
            setParams(tempParams)
          } } selected={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "address") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <Addresses API={ API } items={ _.get(params, item.name) } selectAddress={ (addr) => { 
            let tempParams = {...params}
            _.set(tempParams, item.name, {uuid: addr})
            setParams(tempParams)
          } } selectedAddr={ _.get(params, item.name)?.uuid || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "subtitle") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h2>{ item.value }</h2>
        </div>
      )
    } else if (item.type === "null") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="null"></div>
      )
    } else if (item.type === "separator") {
      return (
        <div style={{minWidth: "100%", marginTop: "24px"}} key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="separator"><hr/></div>
      )
    } else if (item.type === "number") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <input type="number" name={ item.name } value={ _.get(params, item.name) } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false } disabled={item.disabled ? true : false}/>
        </div>
      )
    } else if (item.type === "checkbox") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="checkbox">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <input type="checkbox" id={ i } name={ item.name } onChange={ (e) => { let tempParams = {...params}; tempParams[item.name] = !tempParams[item.name] || false; setParams(tempParams) } } checked={ _.get(params, item.name) }/>
          <label htmlFor={ i }></label>
        </div>
      )
    } else if(item.type === "textarea") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <textarea name={ item.name } value={ _.get(params, item.name) } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false }></textarea>
        </div>
      )
    } else if(item.type === "multiselect") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <MultiselectComponent API={API} table={item.table} endpoint={item.endpoint} selected={_.get(params, item.name) || []} select={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e); setParams(tempParams) } }/>
        </div>
      )
    } else if(item.type === "terms") {
      let val = _.get(params, item.name)

      if (!val.file) {
        val.file = {uuid: "", restricted: params.restricted}
      }

      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <AddTerm restricted={params.restricted} API={ API } image={ val.file } onChange={ (track) => {
            val.file = track
            val.file.restricted = params.restricted
            let tempParams = {...params}
            _.set(tempParams, item.name, val.file)
            setParams(tempParams)
            } }/>
        </div>
      )
    } else if(item.type === "image") {
      let val = _.get(params, item.name) || {}

      if (!val) {
        val = {uuid: "", restricted: params.restricted}
      }

      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <SingleImageUpload restricted={params.restricted} API={ API } image={ val } onChange={ (track) => {
            val = track
            val.restricted = params.restricted
            let tempParams = {...params}
            _.set(tempParams, item.name, val)
            setParams(tempParams)
            } }/>
        </div>
      )
    } else {
      return false
    }
  })

  return (
    <section className="new">
      <div className="container">
      <div className='buttons'>
          <div className="btn" onClick={ () => { setShowImport(true) } }><span>Data import</span></div>
        </div>
        <h1>{ data.name } - add</h1>
        <form className="fields">
          { user.role === "ADMIN" || user.role === "Moderator" ? <div className="add-top" onClick={ () => { validateForm(save) } }>Add</div> : null }
          <div className="back-top" onClick={ () => { history(-1) } }>Go back</div>
          { fields }
          { user.role === "ADMIN" || user.role === "Moderator" ? <div className="add-bottom" onClick={ () => { validateForm(save) } }>Add</div> : null }
          <div className="back-bottom" onClick={ () => { history(-1) } }>Go back</div>
          { preloader ? <div className="preloader"><span></span></div> : null }
        </form>
      </div>
      <Modal open={ showImport } hidePopup={ () => setShowImport(false) }>
        <div className="confirm">
          <h4>Import data</h4>
          <textarea value={importedData} onChange={ (e) => { setImportedData(e.target.value) } }></textarea>
          <p>Remember to check content before saving!</p>
          <div className="btn-holder">
            <button className="btn" onClick={ () => importData() }><span>Import</span></button>
            <button className="btn" onClick={ () => setShowImport(false) }><span>Close</span></button>
          </div>
        </div>
      </Modal>
      <Modal open={ showImportError ? true : false } hidePopup={ () => setShowImportError(false) }>
        <div className="confirm">
          <h4>Wrong data</h4>
          <p>{ showImportError }</p>
          <div className="btn-holder">
            <button className="btn" onClick={ () => setShowImportError(false) }><span>Close</span></button>
          </div>
        </div>
      </Modal>
    </section>
  )
}